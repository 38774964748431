<template>
  <base-sidebar
    :value="value"
    @input="$emit('input', $event)"
    fixed
    right
    temporary
    :width="width"
    class="pa-2"
    v-bind="{ ...$attrs  }"
    v-on="{ ...$listeners }"
  >
    <v-card-title
      class="headline"
    >{{ title }}</v-card-title>

    <v-card-subtitle>{{ subtitle }}</v-card-subtitle>

    <v-divider class="my-2"></v-divider>

    <v-container>
      <slot />
    </v-container>

    <v-divider class="my-2"></v-divider>

    <v-card-actions>
      <base-sidebar-btn
        v-if="(save != null)"
        @click="save()"
        :label="saveLabel"
        :icon="saveIcon"
        :btn-class="!$store.state.isDarkMode ? 'mr-4 blue' : 'mr-4 blue darken-3'"
        icon-color="white"
      ></base-sidebar-btn>
      <base-sidebar-btn
        @click="$emit('input', false)"
        icon="mdi-close"
        :btn-class="!$store.state.isDarkMode ? 'grey lighten-5' : 'grey darken-4'"
        :icon-color="!$store.state.isDarkMode ? 'grey' : 'white'"
      ></base-sidebar-btn>
      <slot name="actions" />
    </v-card-actions>
  </base-sidebar>
</template>

<script>
export default {
  name: 'BaseSidebarEditor',

  inheritAttrs: false,

  props: {
    value: {},
    width: {
      type: [Number, String],
      default: 350,
    },
    title: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
    save: {
      type: Function,
      default: null,
    },
    saveLabel: {
      type: String,
      default: 'Save',
    },
    saveIcon: {
      type: String,
      default: 'mdi-content-save',
    },
  },

};
</script>
