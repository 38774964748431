<template>
  <v-dialog
    :value="value"
    @input="($event) ? $emit('input', value) : $emit('input', null)"
    width="100%"
    max-width="500px"
  >
    <v-card
      class="pb-3"
    >
      <v-card-title style="word-break: normal;">
        <slot name="title"></slot>
      </v-card-title>
      <v-card-subtitle>
        <slot name="subtitle"></slot>
      </v-card-subtitle>
      <div>
        <slot name="content"></slot>
      </div>
      <v-card-actions>
        <slot name="actions"></slot>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'BaseDialogPrompt',

  inheritAttrs: false,

  props: {
    value: {},
  },

  watch: {
    value() {
      this.$emit('input', this.value);
    },
  },
};
</script>
