import Vue from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import store from "./store";
import router from './router';
import upperFirst from 'lodash/upperFirst';
import camelCase from 'lodash/camelCase';
import axios from 'axios';
import VueAxios from 'vue-axios';
import AsyncComputed from 'vue-async-computed';
import "@fontsource/roboto/100.css";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import "@fontsource/roboto/900.css";
import "@mdi/font/css/materialdesignicons.min.css";
import '@/assets/style.css';

// Load third-party components
Vue.use(VueAxios, axios);
Vue.use(AsyncComputed);

// Load global components (UI blocks and elements)
const requireComponent = require.context(
  // The relative path of the components folder
  '@/components/base',
  // Whether or not to look in subfolders
  true,
  // The regular expression used to match base component filenames
  /[A-Z]\w+\.(vue|js)$/
);

requireComponent.keys().forEach(fileName => {
  // Get component config
  const componentConfig = requireComponent(fileName);

  // Get PascalCase name of component
  const componentName = upperFirst(
    camelCase(
      // Gets the file name regardless of folder depth
      fileName
        .split('/')
        .pop()
        .replace(/\.\w+$/, '')
    )
  );

  // Register component globally
  Vue.component(
    componentName,
    // Look for the component options on `.default`, which will
    // exist if the component was exported with `export default`,
    // otherwise fall back to module's root.
    componentConfig.default || componentConfig
  );
});

if (process.env.NODE_ENV == 'development') {
  // enable "use production build" tip
  Vue.config.productionTip = true;

  // enable devtools inspection
  Vue.config.devtools = true;
  Vue.config.debug = true;
  Vue.config.silent = false;
} else {
  // disable "use production build" tip
  Vue.config.productionTip = false;

  // disable devtools inspection
  Vue.config.devtools = false;
  Vue.config.debug = false;
  Vue.config.silent = true;
}

// set the global variables
Object.defineProperty(Vue.prototype, '$darkMode', {
  get () {
    return this.$vuetify.theme.dark;
  },
  set (value) {
    this.$vuetify.theme.dark = value;
  },
});

new Vue({
  vuetify,
  router,
  store: store,
  render: h => h(App)
}).$mount('#app');
