<template>
  <v-app-bar
    class="mx-auto background"
    elevation="0"
    app
    absolute
    dense
    style="max-width: 1080px;"
  >
    <v-toolbar-title></v-toolbar-title>

    <a
      href="https://webwalker.io/"
      target="_blank"
    >
      <v-avatar
        v-if="$store.getters.authenticated"
        size="80"
        class="blue--text mr-3 text--darken-3"
        style="position: relative; top: 30px;"
      >
        <v-img
          :src="(this.$darkMode) ? '../../assets/icon-white.png' : '../../assets/icon-black.png'"
          max-width="80"
          max-height="80"
          contain
        ></v-img>
      </v-avatar>
    </a>

    <v-spacer></v-spacer>

    <base-main-nav-btn
      v-if="$store.getters.authenticated"
      to="/"
      icon="mdi-view-dashboard"
      class="mx-3"
    >Dashboard</base-main-nav-btn>

    <v-btn
      v-if="$store.getters.authenticated"
      to="/settings"
      link
      icon
      class="mx-1"
      small
      style="display: none;"
    >
      <v-icon small>mdi-account-circle</v-icon>
    </v-btn>

    <div
      v-if="$store.getters.authenticated"
    >
      <v-tooltip v-if="$darkMode" bottom>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" small icon @click="$store.dispatch('switchDarkMode', false)">
            <v-icon small color="yellow" class="mr-1">mdi-moon-waxing-crescent</v-icon>
          </v-btn>
        </template>
        <span>Disable dark mode</span>
      </v-tooltip>
      <v-tooltip v-else bottom>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" small icon @click="$store.dispatch('switchDarkMode', true)">
            <v-icon small color="yellow">mdi-white-balance-sunny</v-icon>
          </v-btn>
        </template>
        <span>Enable dark mode</span>
      </v-tooltip>
    </div>

    <v-btn
      class="main-nav-btn mx-2"
      small
      text v-if="!this.$store.getters.authenticated"
      :to="{name: 'Login'}"
    >
      <v-icon class="mr-1" small>mdi-login</v-icon>
      Login
    </v-btn>
    <v-btn
      class="mx-2"
      icon
      small
      v-if="this.$store.getters.authenticated"
      @click="logoutConfirmation = true"
    >
      <v-icon small>mdi-logout</v-icon>
    </v-btn>
    <base-dialog-confirmation
      v-model="logoutConfirmation"
      prompt="Do you really want to logout?"
      confirm-text="Logout"
      confirm-color="red"
      @confirm="logout"
      @cancel="logoutConfirmation = false"
    ></base-dialog-confirmation>
  </v-app-bar>
</template>

<script>
export default {
  name: 'MainNav',

  components: {
    //
  },

  data() {
    return {
      logoutConfirmation: false,
    };
  },

  computed: {
    //
  },

  methods: {

    logout: function () {
      this.$emit('logout');
    },

  },
};
</script>

<style lang="scss">
a:not(.clean):active, a:not(.clean):hover {
    opacity: 0.8;
}
</style>
