<template>
  <v-menu
    v-model="showMenu"
    bottom
    :close-on-content-click="false"
  >
    <template v-slot:activator="menu">
      <v-text-field
        v-model="dateRangeText"
        label="Date Range"
        prepend-icon="mdi-calendar"
        readonly
        hide-details
        outlined
        dense
        style="width: 250px;"
        @click="showMenu = true"
        v-bind="{ ...$attrs, ...menu.attrs }"
        v-on="{ ...$listeners, ...menu.on }"
      ></v-text-field>
    </template>

    <v-card>
      <v-date-picker
        :value="value"
        @input="changeDateRange($event)"
        range
        v-bind="{ ...$attrs  }"
        v-on="{ ...$listeners }"
      ></v-date-picker>
      <div
        class="d-flex"
      >
        <base-sidebar-btn
          @click="showMenu = false"
          label="Confirm"
          icon="mdi-check"
          :btn-class="!$store.state.isDarkMode ? 'grey lighten-5' : 'grey darken-4'"
          :icon-color="!$store.state.isDarkMode ? 'grey' : 'white'"
        ></base-sidebar-btn>
        <v-spacer />
        <base-sidebar-btn
          @click="clearDateRange()"
          label="Reset"
          icon="mdi-cancel"
          :btn-class="!$store.state.isDarkMode ? 'grey lighten-5' : 'grey darken-4'"
          :icon-color="!$store.state.isDarkMode ? 'grey' : 'white'"
        ></base-sidebar-btn>
      </div>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  name: 'BaseDaterangePicker',

  inheritAttrs: false,

  props: {
    value: {
      type: Array,
      default: () => ([
          this.formatDate(new Date()),
          this.formatDate(new Date()),
      ]),
    },
    lastDays: {
      type: [Number, String],
      default: 0,
    },
  },

  data: () => ({
    showMenu: false,
  }),

  computed: {
    dateRangeText () {
      if (this.value) {
        return this.value.join(' to ');
      }
      return '';
    },
  },

  watch: {
    value() {
      this.$emit('input', this.value);
    },
  },

  mounted() {
    this.initDateRange();
  },

  methods: {

    initDateRange: function () {
      if (this.lastDays) {
        var start_date = new Date();
        start_date.setDate(start_date.getDate() - parseInt(this.lastDays));
        this.$emit('input', [
          this.formatDate(start_date),
          this.formatDate(new Date()),
        ]);
      }
    },

    changeDateRange: function (value) {
      if (!value || (value.length == 1)) {
        this.$emit('input', value);
      } else {
        var start = new Date (value[0]);
        var end = new Date (value[1]);
        if (start > end) {
          let tmp = value[0];
          value[0] = value[1];
          value[1] = tmp;
          this.$emit('input', value);
        }
      }
    },

    formatDate: function (date) {
      return date.getFullYear() + '-' + ('0'+(date.getMonth() + 1)).slice(-2) + '-' + ('0'+(date.getDate())).slice(-2);
    },

    clearDateRange: function () {
      this.showMenu = false;
      this.initDateRange();
    },

  }
};
</script>
