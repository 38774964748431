<template>
  <v-autocomplete
    :value="value"
    @input="($event) => $emit('input', $event)"
    :items="currencies"
    label="Currency"
    v-bind="{ ...$attrs  }"
    v-on="{ ...$listeners }"
  >
    <template v-slot:progress>
      <v-progress-circular
          class="ma-auto"
          indeterminate
          color="primary"
      ></v-progress-circular>
    </template>
  </v-autocomplete>
</template>

<script>
export default {
  name: 'BaseCurrencyPicker',

  inheritAttrs: false,

  props: {
    value: {
      type: String,
      default: '',
    },
  },

  data: () => ({
    currencies: [
      'USD',
      'EUR',
      'BGN',
      'RUB',
    ],
  }),

  watch: {
    value() {
      this.$emit('input', this.value);
    },
  },

  created() {
    //
  },

  methods: {

    //

  }
};
</script>
