<template>
  <div
    class="d-flex w-100"
    style="position: absolute; top: 0; justify-content: center; pointer-events: none;"
  >
    <div
      v-for="(error, index) in errors"
      :key="index"
      style="z-index: 1; pointer-events: auto;"
    >
      <div
        v-for="(messages, field) in error"
        :key="field"
        class="mx-4"
      >
        <v-alert
          v-for="(message, messageIndex) in messages"
          :key="messageIndex"
          type="error"
          elevation="2"
          class="my-2"
          tile
          dense
          dismissible
        >{{ message }}</v-alert>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseErrorAlerts',

  computed: {
    errors: function () {
      return this.$store.state.errors;
    },
  },
};
</script>
