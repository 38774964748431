<template>
  <base-dialog-prompt
    :value="value"
    @input="$emit('input', $event)"
  >
    <template v-slot:title>
      {{ prompt }}
    </template>
    <template v-slot:actions>
      <v-btn
        :color="confirmColor"
        dark
        @click.native="confirm()"
      >{{ confirmText }}</v-btn>
      <v-spacer></v-spacer>
      <v-btn
        :color="cancelColor"
        @click.native="cancel()"
      >{{ cancelText }}</v-btn>
    </template>
  </base-dialog-prompt>
</template>

<script>
export default {
  name: 'BaseDialogConfirmation',

  inheritAttrs: false,

  props: {
    value: {},
    prompt: {
      type: String,
      default: "Do you really want to proceed?",
    },
    confirmText: {
      type: String,
      default: "Confirm",
    },
    cancelText: {
      type: String,
      default: "Cancel",
    },
    confirmColor: {
      type: String,
      default: "red",
    },
    cancelColor: {
      type: String,
      default: "",
    },
  },

  watch: {
    value() {
      this.$emit('input', this.value);
    },
  },

  methods: {

    confirm: function () {
      this.$emit('confirm');
    },

    cancel: function () {
      this.$emit('cancel');
    },

  },
};
</script>
