<template>
  <div
    class="d-inline-block"
    v-bind="{ ...$attrs }"
    v-on="{ ...$listeners }"
  >
    <v-edit-dialog
      :return-value.sync="value"
      @save="$emit('save', value)"
    >
      <span
        v-if="!$slots.display"
      >{{ (value) ? value : 'NULL' }}</span>
      <slot
        name="display"
      ></slot>
      <template v-slot:input>
        <v-text-field
          v-if="(!type && !$slots.default) || (type == 'string')"
          :value="value"
          @input="$emit('input', $event)"
          single-line
        ></v-text-field>
        <v-text-field
          v-else-if="(type == 'number')"
          :value="value"
          @input="$emit('input', $event)"
          single-line
          type="number"
        ></v-text-field>
        <base-currency-picker
          v-else-if="(type == 'currency')"
          :value="value"
          @change="$emit('change', $event)"
          @input="$emit('input', $event)"
          clearable
          single-line
        ></base-currency-picker>
        <div
          v-else
        >
          <slot />
        </div>
      </template>
    </v-edit-dialog>
  </div>
</template>

<script>
export default {
  name: 'BaseDataTableEditableField',

  props: {
    value: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: '',
    },
  },

};
</script>
