<template>
  <v-app
    id="webwalker"
    :class="{ 'is-auth': isAuthView }"
  >
    <main-nav
      v-if="!isAuthView"
      @logout="logout()"
    ></main-nav>

    <v-main
      :class="{ 'background': !isAuthView, 'blue darken-3': isAuthView }"
    >

      <!-- Provides the application the proper gutter -->
      <!-- <v-container fluid> -->

        <!-- If using vue-router -->
        <transition-container>
          <router-view></router-view>
        </transition-container>
      <!-- </v-container> -->
    </v-main>
  </v-app>
</template>

<script>
import AuthApi from "@/api/AuthApi";
import MainNav from '@/components/layout/MainNav.vue';
import TransitionContainer from '@/components/layout/TransitionContainer.vue';

export default {
  name: 'WebwalkerAffiliate',

  components: {
    MainNav,
    TransitionContainer,
  },

  data: () => ({
    layout: 'top',
    interval: null,
    refreshInterval: 60,
  }),

  computed: {
    isSidebarLayout: function () {
      return (this.layout == 'sidebar');
    },

    isTopLayout: function () {
      return (this.layout == 'top');
    },

    isDarkMode() {
      return this.$store.state.isDarkMode;
    },

    isAuthView() {
      // Check if on auth pages (login, sign up, password reset, etc)
      if (this.$route && this.$route.meta) {
        return this.$route.meta.public;
      }

      return false;
    },
  },

  watch: {
    isDarkMode() {
      this.$darkMode = this.$store.state.isDarkMode;
      this.rootScrollColor();
    },

    $route (/*to, from*/) {
      // Clear the list of erros when user goes to another page
      this.$store.dispatch('clearErrors');
    },
  },

  mounted() {
    // Refresh the user data periodically
    this.loadData();
    this.interval = setInterval(() => {
        this.loadData();
    }, this.refreshInterval * 1000);
    this.rootScrollColor();
  },

  beforeDestroy() {
    if (this.interval) clearInterval(this.interval);
  },

  methods: {

    loadData: function () {
      // Load user data from backend
      this.$store.dispatch('loadUser');

      // Load dark mode option from the user settings
      this.$darkMode = this.$store.state.isDarkMode;
    },

    logout: function () {
      // Clear tokens on the backend
      AuthApi.logout()
      .then(() => {
        // Clear local storage
        localStorage.clear();

        // Reload the tab
        window.location.reload();
      })
      .catch(error => {
        if (error.response.data.errors) {
          this.$store.dispatch('pushErrors', error.response.data.errors);
        }
        localStorage.clear();
      });
    },

    rootScrollColor: function () {
      let root = document.querySelector(':root');
      if (this.isDarkMode) {
        root.classList.add('scroll-dark');
      } else {
        root.classList.remove('scroll-dark');
      }
    },

  },
}
</script>
