import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import SecureLS from "secure-ls";
import AuthApi from "@/api/AuthApi";
import SettingsApi from "@/api/SettingsApi";

// Use Secure (encrypted) Local Storage for storing data in the browser
const ls = new SecureLS({
  encodingType: 'aes',
  isCompression: false,
  encryptionSecret: process.env.VUE_APP_LS_ENCRYPTION_SECRET,
});

Vue.use(Vuex);

const store = new Vuex.Store({
  plugins: [
    createPersistedState({
      key: "webwalker",
      storage: {
        getItem: (key) => {
          try {
            return ls.get(key)
          }
          catch (err) {
            ls.clear()
            return ls.get(key)
          }
        },
        setItem: (key, value) => ls.set(key, value),
        removeItem: (key) => ls.remove(key),
      },
    })
  ],
  state: {
    token: false, // Core API token
    user: false, // Current user details
    isDarkMode: false, // Dark\Light theme
    errors: [], // List of errors
  },
  getters: {
    authenticated(state) {
      // Consider authenticated if has token
      if (state.token) {
        return true;
      }
      return false;
    },
  },
  mutations: {
    setToken(state, token) {
      state.token = token;
    },
    setUser(state, user) {
      state.user = user;
    },
    setDarkMode(state, isDarkMode) {
      state.isDarkMode = isDarkMode;
    },
    pushErrors(state, errors) {
      state.errors.push(errors);
    },
    clearErrors(state) {
      state.errors = [];
    },
  },
  actions: {
    loadUser({ commit }) {
      AuthApi.auth().then(response => {
        // Load user details
        commit('setUser', response.data.data);

        // Load settings
        if (response.data.data.settings) {
          commit('setDarkMode', response.data.data.settings.dark_mode);
        }
      })
      .catch((error) => {
        if (error.response.data.errors) {
          //commit('pushErrors', error.response.data.errors);
        }
        localStorage.clear();
      });
    },
    switchDarkMode({ commit }, isDarkMode) {
      // Store the last choice (dark mode on\off) in the user settings
      SettingsApi.updateUser({
        settings: {'dark_mode': isDarkMode},
      });

      // Switch the dark mode on\off
      commit('setDarkMode', isDarkMode);
    },
    pushErrors({ commit }, errors) {
      // Add an error to the list of errors
      commit('pushErrors', errors);
    },
    clearErrors({ commit, state }) {
      // Clear the list of errors
      if (state.errors.length) {
        commit('clearErrors');
      }
    },
  },
});

export default store;
