<template>
  <v-container
    class="blue darken-3 auth-view"
    fill-height
    fluid
    dark
  >
    <v-row
      justify="center"
      align="center"
    >
      <v-col
        cols="12"
        align="center"
        class="mb-16"
      >
        <div
          class="mb-4 d-flex"
          style="max-width: 766px; align-items: center; justify-content: center;"
        >
          <v-img
            src="@/assets/icon-white.png"
            contain
            max-width="100"
            max-height="75"
            class="d-inline-block"
          ></v-img>
          <div
            class="d-inline-block white--text text-left"
          >
            <div
              class="text-button"
              style="margin-bottom: -10px;"
            >Webwalker</div>
            <div
              class="text-overline"
            >Affiliate</div>
          </div>
        </div>
        <v-card
          color="white"
          light
          elevation="3"
          class="pa-3 mb-4"
          align="left"
          style="max-width: 766px; border-top: 4px solid #2196F3 !important; border-top-left-radius: 0; border-top-right-radius: 0;"
        >
          <v-container>
            <v-row>
              <v-col
                cols="12"
                lg="7"
                md="6"
                sm="12"
              >
                <v-card-title
                  class="headline"
                >
                  {{ title }}
                  <slot name="title"></slot>
                </v-card-title>

                <slot></slot>
              </v-col>
              <v-col
                cols="1"
                lg="1"
                md="1"
                class="d-none d-md-block text-center"
              >
                <v-divider
                  vertical
                  style="border-color: rgba(0, 0, 0, 0.75);"
                ></v-divider>
              </v-col>
              <v-col
                cols="12"
                lg="4"
                md="5"
                sm="12"
              >
                <v-divider
                  class="d-md-none"
                  style="border-color: rgba(0, 0, 0, 0.75);"
                ></v-divider>

                <br />

                <slot name="side"></slot>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
        <div
          class="mb-16"
          style="position: relative;"
        >
          <base-error-alerts
          ></base-error-alerts>
        </div>
      </v-col>
    </v-row>
    <slot name="other"></slot>
  </v-container>
</template>

<script>
export default {
  name: 'BaseAuthViewContainer',

  props: {
    title: {
      type: String,
      default: '',
    },
  },

  computed: {
    errors: function () {
      return this.$store.state.errors;
    },
  },
};
</script>

<style scoped>
.auth-view {
  min-height: 100vh !important;
  min-height: calc(100vh - 48px) !important;
  /*background: url('../../../assets/vintage-machinery-2.png');*/
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center bottom;
  box-shadow:inset 0 0 0 2000px rgba(21, 101, 192, 0.9);
}
</style>
