<template>
  <div>
    <v-container
      fluid
      class="main-view"
    >
      <v-row>
        <v-col
          v-if="title"
          cols="12"
          class="pa-0"
        >
          <base-view-header
            :title="title"
            :hasTour="$listeners['tour-view']"
            @tour-view="$emit('tour-view')"
          >
            <template v-slot:filters="props">
              <slot name="filters" v-bind="props" />
            </template>
            <template v-slot:actions="props">
              <slot name="actions" v-bind="props" />
            </template>
          </base-view-header>
        </v-col>
        <v-col
          v-if="dense"
          cols="12"
          :class="{ 'pt-8': (!fullwidth), 'pa-0': (fullwidth) }"
        >
          <v-container>
            <slot></slot>
          </v-container>
          <base-error-alerts
          ></base-error-alerts>
        </v-col>
        <v-col
          v-else
          cols="12"
          :class="{ 'pt-8': (!fullwidth), 'px-16': (!fullwidth), 'pa-0': (fullwidth) }"
        >
          <slot></slot>
        </v-col>
      </v-row>
    </v-container>
    <slot name="other"></slot>
  </div>
</template>

<script>
export default {
  name: 'BaseViewContainer',

  props: {
    title: {
      type: String,
      default: '',
    },
    fullwidth: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
.main-view {
  min-height: 100vh !important;
  min-height: calc(100vh - 48px) !important;
  /*background: url('../../../assets/vintage-machinery-2.png');*/
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center bottom;
  /*box-shadow:inset 0 0 0 2000px rgba(250, 250, 250, 0.9);*/
}
</style>
