import Vue from 'vue';
import VueRouter from 'vue-router';
import store from "@/store";

Vue.use(VueRouter)

const routes = [

  // PUBLIC ROUTES

  {
    path: '/signup',
    name: 'Sign Up',
    component: () => import('@/views/public/SignUpView.vue'),
    meta: {
      public: true,
      account: false,
      owner: false,
      title: 'Sign Up',
    },
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/public/LoginView.vue'),
    meta: {
      public: true,
      account: false,
      owner: false,
      title: 'Login',
    },
  },
  {
    path: '/forgot-password',
    name: 'Forgot Password',
    component: () => import('@/views/public/ForgotPasswordView.vue'),
    meta: {
      public: true,
      account: false,
      owner: false,
      title: 'Forgot Password',
    },
  },
  {
    path: '/reset-password',
    name: 'Reset Password',
    component: () => import('@/views/public/ResetPasswordView.vue'),
    meta: {
      public: true,
      account: false,
      owner: false,
      title: 'Reset Password',
    },
  },
  {
    path: '/verify-email',
    name: 'Verify Email',
    component: () => import('@/views/public/VerifyEmailView.vue'),
    meta: {
      public: true,
      owner: false,
      title: 'Verify Email',
    },
  },

  // PRIVATE ROUTES

  {
    path: '/',
    name: 'Dashboard',
    component: () => import('@/views/private/DashboardView.vue'),
    meta: {
      public: false,
      title: 'Dashboard',
    },
  },
  {
    path: '/settings',
    name: 'Settings',
    component: () => import('@/views/private/SettingsView.vue'),
    meta: {
      public: false,
      title: 'Settings',
    },
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.meta.public) {
    next()
  } else {
    if (store.getters.authenticated) {
      next()
    } else {
      next('/login')
    }
  }
})

router.afterEach((to) => {
  Vue.nextTick( () => {
    document.title = to.meta.title ? to.meta.title + ' | Webwalker Affiliate' : 'Webwalker Affiliate';
  });
})

export default router
