<template>
  <v-dialog
    :value="value"
    @input="($event) ? $emit('input', value) : $emit('input', null)"
    fullscreen
  >
    <div
      class="dialog-fullscreen overflow-x-hidden"
    >
      <slot name="prepend"></slot>
      <v-card
        color="transparent"
        tile
        flat
      >
        <v-toolbar
          dark
          color="primary"
          dense
        >
          <v-btn
            icon
            dark
            @click="$emit('input', null)"
            small
          >
            <v-icon small>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{ title }}</v-toolbar-title>
        </v-toolbar>
        <slot></slot>
      </v-card>
    </div>
  </v-dialog>
</template>

<script>
export default {
  name: 'BaseDialogFullscreen',

  inheritAttrs: false,

  props: {
    value: {},
    title: {
      type: String,
      default: "",
    },
  },

  watch: {
    value() {
      this.$emit('input', this.value);
    },
  },
};
</script>

<style scoped>
.dialog-fullscreen {
  height: 100%;
  background: rgba(255, 255, 255, 0.85);
  backdrop-filter: blur(2px);
}
.theme--dark .dialog-fullscreen {
  background: rgba(0, 0, 0, 0.85);
}
</style>
