<template>
  <v-menu
    bottom
    :close-on-content-click="false"
  >
    <template v-slot:activator="menu">
      <v-text-field
        v-bind="{ ...$attrs, ...menu.attrs }"
        v-on="{ ...$listeners, ...menu.on }"
        :value="value"
        label="Date and Time"
        prepend-icon="mdi-calendar"
        readonly
      ></v-text-field>
    </template>

    <v-card
      class="d-flex"
    >
      <v-date-picker
        v-model="date"
        @change="changeDateTime"
        first-day-of-week="1"
        flat
        landscape
      ></v-date-picker>

      <v-time-picker
        v-model="time"
        @change="changeDateTime"
        format="24hr"
        flat
        landscape
      ></v-time-picker>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  name: 'BaseDatetimePicker',

  inheritAttrs: false,

  props: {
    value: {
      type: String,
      default: '',
    },
  },

  data: () => ({
    lastValue: null,
    date: '',
    time: '',
  }),

  watch: {
    value() {
      this.loadDateTime();
    },
  },

  mounted() {
    this.loadDateTime();
  },

  methods: {

    loadDateTime: function () {
      if (this.lastValue && (this.lastValue == this.value)) return;

      if (this.value) {
        this.date = (new Date(Date.parse(this.value))).toISOString().split('T')[0];
        this.time = (new Date(Date.parse(this.value))).toTimeString().split(' ')[0];
      } else {
        this.date = this.formatDate(new Date());
        this.time = '00:00';
      }
      this.changeDateTime();
    },

    changeDateTime: function () {
      var val = this.date + ' ' + this.time;
      if (this.value != val) {
        this.lastValue = val;
        this.$emit('input', val);
      }
    },

    formatDate: function (date) {
      return date.getFullYear() + '-' + ('0'+(date.getMonth() + 1)).slice(-2) + '-' + ('0'+(date.getDate())).slice(-2);
    },

  }
};
</script>
