<template>
  <v-text-field
    :value="value"
    @input="$emit('input', $event)"
    @change="load();"
    @click:clear="reset();"
    @keyup="load();"
    label="Search"
    outlined
    dense
    hide-details
    clearable
    class="d-inline-block mx-1"
  ></v-text-field>
</template>

<script>
export default {
  name: 'BaseViewHeaderSearch',

  props: {
    value: {
      type: String,
      default: '',
    },
    search: {
      type: Function,
      default: () => {},
    },
  },

  data () {
    return {
      previousValue: '',
    }
  },

  watch: {
    value() {
      this.load();
    },
  },

  methods: {

    reset() {
      this.$emit('input', this.value);
    },

    load() {
      if (this.value == this.previousValue) return;
      this.previousValue = this.value;
      this.search();
    },

  },
};
</script>
